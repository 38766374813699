import FormControl from '@mui/material/FormControl'
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel'
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import * as React from 'react'

export type TRadioData = {
  value: any
  label: string
  itemProps?: Omit<FormControlLabelProps, 'label' | 'control'>
}

type TAppRaidoGroup = {
  label: string
  datas: TRadioData[]
  labelProps?: FormLabelProps
} & RadioGroupProps

export function AppRadioGroup(props: TAppRaidoGroup) {
  const { label, datas, labelProps, ...radioGroupProps } = props
  return (
    <FormControl>
      <FormLabel id='demo-row-radio-buttons-group-label' {...labelProps}>
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        {...radioGroupProps}
      >
        {datas.map(item => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
            {...item.itemProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
