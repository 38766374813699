import { Query } from '@feathersjs/client'
import UserManagement from 'layouts/users'
import { memo } from 'react'

const defaultQueryValue: Query = {
  roles: {
    $in: ['admin'],
  },
  $skip: 0,
  $limit: 50,
  $sort: {
    createdAt: -1,
  },
}

const onSearchChangeQueryValue = (value: string, prev: Query) => ({
  query: value?.length ? { phone: value } : { ...defaultQueryValue },
})

function AdminManagement() {
  return (
    <UserManagement
      defaultQuery={defaultQueryValue}
      enableRemoveClient
      onSearchChangeQuery={onSearchChangeQueryValue}
      isAdmin
    />
  )
}

export default memo(AdminManagement)
