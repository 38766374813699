import { Query } from '@feathersjs/client'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import ConfirmationDialog from 'components/ConfirmDialog'
import { ActionButton, ItemDialog } from 'components/GridItem'
import { ReportedBy } from 'components/GridItem/ItemDialog'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DataTable from 'examples/Tables/DataTable'
import { feathersApp, getFileFullPath } from 'helpers'
import { useQuery } from 'helpers/hooks'
import { getSmallerImage } from 'helpers/user'
import { uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'

export type ReportModelConfig = {
  model:
    | 'event-reports'
    | 'user-reports'
    | 'feed-reports'
    | 'feed-comment-reports'
    | 'feed-reply-reports'
  reportedModel:
    | 'events'
    | 'users'
    | 'feeds'
    | 'feedComment'
    | 'feedRelyComment'
  modelDisplay: 'event' | 'user' | 'talk' | 'talk comment' | 'talk reply '
  fields: {
    title: string
    body: string
    image: string
    reportedId: string
    reportedModel: string
    reportedBy: string
    reportedReason: string
  }
}
const defaultQueryValue: Query = {
  $skip: 0,
  $limit: 50,
  $sort: {
    createdAt: -1,
  },
}
const onPaginationChangeQueryValue = (
  limit: number,
  skip: number,
  prev?: Query,
) => {
  return {
    query: {
      ...prev.query,
      $skip: skip,
      $limit: limit,
    },
  }
}
const defaultProps = {
  defaultQuery: defaultQueryValue,
  onChangePaginationQuery: onPaginationChangeQueryValue,
}

type ReportProps = {
  config: ReportModelConfig
  useReportData: (
    result: Array<any> | null,
    handleOpenConfirmDlg: (service: string, value: string) => void,
    getReportImageFullPath?: (images?: string[]) => string | null,
  ) => { columns: Array<any>; rows: Array<any> }
  getReportImageFullPath?: (images?: string[]) => string | null
  defaultQuery: Query
  onChangePaginationQuery?: (limit: number, skip: number, prev?: Query) => Query
}

function Report({
  config: { model, modelDisplay, reportedModel, fields },
  useReportData,
  getReportImageFullPath,
  defaultQuery,
  onChangePaginationQuery,
}: ReportProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const modelService = useRef(feathersApp.service(model))
  const reportedModelService = useRef(feathersApp.service(reportedModel))

  const [showConfirmDlg, setShowConfirmDlg] = useState(false)
  const [showItemDlg, setShowItemDlg] = useState(false)
  const [dlgBody, setDlgBody] = useState<string | null>(
    `Are you sure you want to delete this ${modelDisplay}?`,
  )
  const [dlgValue, setDlgValue] = useState<string | string[] | null>(null)
  const [dlgService, setDlgService] = useState<string | null>(null)
  const [dlgTitle, setDlgTitle] = useState<string | null>(null)
  const [dlgImage, setDlgImage] = useState<string | null>(null)
  const [reportedBy, setReportedBy] = useState<ReportedBy | null>(null)
  const [reportedReason, setReportedReason] = useState<string | null>(null)
  const [query, setQuery] = useState<Query>({
    query: defaultQuery,
  })
  const { fetching, error, result, setResult, total } = useQuery(
    model,
    0,
    100,
    query,
  )

  const handleOpenConfirmDlg = useCallback(
    (service: string, value: string | string[]) => {
      if (Array.isArray(value)) {
        if (service === reportedModel) {
          setDlgBody(
            `Are you sure you want to delete selected ${modelDisplay}s ?`,
          )
        } else {
          setDlgBody('Are you sure you want to ignore selected reports?')
        }
      } else {
        if (service === reportedModel) {
          setDlgBody(`Are you sure you want to delete this ${modelDisplay} ?`)
        } else {
          setDlgBody('Are you sure you want to ignore this reports')
        }
      }

      setDlgService(service)
      setDlgValue(value)
      setShowConfirmDlg(true)
    },
    [],
  )

  const handleOpenReportDlg = useCallback(
    (id: string) => {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          id,
        }).toString(),
      })
    },
    [location.pathname, navigate],
  )

  const handleCloseDlg = useCallback(
    (service, value) => {
      if (Boolean(service) && Boolean(value)) {
        const values = Array.isArray(value) ? value : [value]
        if (service === reportedModel && Array.isArray(result)) {
          const foundReports = result.filter(r => values.includes(r._id))
          if (foundReports.length) {
            let foundIds = []
            foundReports.forEach(foundReport => {
              foundIds = foundIds.concat(
                result
                  .filter(
                    r =>
                      r[fields.reportedId] === foundReport[fields.reportedId],
                  )
                  .map(r => r._id),
              )
            })

            foundIds = uniq(foundIds)

            const promises = foundIds.map(_id =>
              modelService.current.remove(_id).catch(err => console.error(err)),
            )

            if (reportedModel === 'users') {
              foundReports.forEach(foundReport => {
                promises.push(
                  reportedModelService.current
                    .patch(foundReport[fields.reportedId], {
                      isDeactivated: true,
                    })
                    .catch(err => console.error(err)),
                )
              })
            } else {
              foundReports.forEach(foundReport => {
                promises.push(
                  reportedModelService.current
                    .remove(foundReport[fields.reportedId])
                    .catch(err => console.error(err)),
                )
              })
            }

            Promise.all(promises).then(() => {
              setResult(prev => {
                if (Array.isArray(prev)) {
                  return prev.filter(p => !foundIds.includes(p._id))
                }

                return prev
              })
            })
          }
        } else {
          const promises = values.map(_id =>
            modelService.current.remove(_id).catch(err => console.error(err)),
          )

          Promise.all(promises).then(() => {
            setResult(prev => {
              if (Array.isArray(prev)) {
                return prev.filter(p => !values.includes(p._id))
              }

              return prev
            })
          })
        }
      }

      setDlgTitle(null)
      setDlgValue(null)
      setDlgBody(null)
      setDlgImage(null)
      setDlgService(null)
      setReportedBy(null)
      setReportedReason(null)
      setShowConfirmDlg(false)
      setShowItemDlg(false)
      navigate({
        pathname: location.pathname,
        search: '',
      })
    },
    [result, location.pathname, navigate],
  )

  const handleSetReportDetail = useCallback(
    id => {
      if (Array.isArray(result) && result.length) {
        const foundReport = result.find(r => r._id === id)
        if (foundReport) {
          setDlgService(reportedModel)
          setDlgValue(id)
          setDlgTitle(foundReport[fields.reportedModel]?.[fields.title])
          setDlgBody(foundReport[fields.reportedModel]?.[fields.body])
          setDlgImage(
            getReportImageFullPath
              ? getReportImageFullPath(
                  foundReport[fields.reportedModel]?.[fields.image],
                )
              : getFileFullPath(
                  foundReport[fields.reportedModel]?.[fields.image],
                ),
          )
          setReportedBy(foundReport[fields.reportedBy])
          setReportedReason(foundReport[fields.reportedReason])
          setShowItemDlg(true)
        }
      }
    },
    [result],
  )

  const handleRowClick = useCallback(
    (id: string) => {
      handleOpenReportDlg(id)
    },
    [handleOpenReportDlg],
  )

  const handleDeleteAll = useCallback(
    data => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg(
        reportedModel,
        data?.map((r: any) => r.id.props.children),
      )
    },
    [handleOpenConfirmDlg],
  )

  const ignoreAll = useCallback(
    data => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg(
        model,
        data?.map((r: any) => r.id.props.children),
      )
    },
    [handleOpenConfirmDlg],
  )

  const composeMultipleActions = useMemo(
    () => [
      <ActionButton
        key='ignoreReportBtn'
        text='Ignore Selecteds Reports'
        className='ignoreReportBtn ignore-all'
        handleAction={ignoreAll}
      />,
      <ActionButton
        key='deleteBtn'
        text={`Delete Selected ${modelDisplay}s`}
        className='reportedItemDeleteBtn delete-all'
        handleAction={handleDeleteAll}
        startIcon={<DeleteIcon style={{ position: 'relative', top: -1 }} />}
      />,
    ],
    [handleDeleteAll, ignoreAll],
  )

  useEffect(() => {
    if (Array.isArray(result) && result.length && Boolean(location.search)) {
      const params = new URLSearchParams(location.search)
      if (params.has('id')) {
        const id = params.get('id')
        handleSetReportDetail(id)
      }
    }
  }, [result, location.search, handleSetReportDetail])

  const { columns, rows } = useReportData(
    result,
    handleOpenConfirmDlg,
    getReportImageFullPath,
  )

  const onPaginationChange = useAsyncDebounce(
    (pageSize: number, pageIndex: number) => {
      setQuery(prev => ({
        ...onChangePaginationQuery(pageSize, pageSize * pageIndex, prev),
      }))
    },
    500,
  )
  const pageCount = useMemo(() => {
    if (total) {
      const limit = query.query['$limit'] || 50
      return Math.ceil(total / limit)
    }
    return 0
  }, [total, query.query['$limit']])
  return (
    <DashboardLayout>
      <Box pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                  pagination={{ pageCount, total }}
                  fetching={fetching}
                  error={error}
                  rowkeyId='id'
                  onRowClick={data => handleRowClick(data)}
                  multipleActions={composeMultipleActions}
                  onPaginationChange={onPaginationChange}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationDialog
        open={showConfirmDlg}
        title='Warning'
        body={dlgBody}
        service={dlgService}
        value={dlgValue}
        onClose={handleCloseDlg}
      />
      <ItemDialog
        open={showItemDlg}
        title={dlgTitle}
        body={dlgBody}
        image={getSmallerImage(dlgImage)}
        service={dlgService}
        value={dlgValue}
        reportedBy={reportedBy}
        reason={reportedReason}
        onClose={handleCloseDlg}
      />
    </DashboardLayout>
  )
}
Report.defaultProps = defaultProps
export default Report
