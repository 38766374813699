// @ts-nocheck
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MDAvatar from 'components/MDAvatar'

type AuthorProps = {
  image: string | null
  name?: string
  email?: string
  reverse?: boolean
  isDeactivated?: boolean
  handleOnClick?: (e?: any) => void
}

export function Author({
  image,
  name,
  email,
  reverse,
  isDeactivated,
  handleOnClick,
}: AuthorProps) {
  return (
    <Box
      display='flex'
      alignItems='center'
      lineHeight={1}
      onClick={e => {
        handleOnClick && e.stopPropagation()
        handleOnClick && handleOnClick()
      }}
      flexDirection={reverse ? 'row-reverse' : 'row'}
    >
      {Boolean(image) ? (
        <MDAvatar src={image} name={name} size='sm' />
      ) : (
        <AccountCircleIcon fontSize='large' />
      )}
      <Box
        ml={2}
        lineHeight={1}
        textAlign='left'
        display={'flex'}
        flexDirection='column'
      >
        <Typography display='block' variant='button' fontWeight='medium'>
          {name}{' '}
          {isDeactivated && (
            <Typography
              variant='caption'
              fontWeight='medium'
              sx={{ color: 'orange' }}
            >
              Deactivated
            </Typography>
          )}
        </Typography>
        <Typography variant='caption'>{email}</Typography>
      </Box>
    </Box>
  )
}
