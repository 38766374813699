import Box from '@mui/material/Box'
import Popover, { PopoverOrigin } from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'

type PopoverItemProps = {
  name: string
  content: string
  popoverContent: string
  contentVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
}

const styles = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  } as PopoverOrigin,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  } as PopoverOrigin,
}

export default function PopoverItem({
  name,
  content,
  popoverContent,
  contentVariant,
}: PopoverItemProps) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = useCallback(event => {
    event?.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handlePopoverClose = useCallback(event => {
    event?.stopPropagation()
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Typography
        aria-owns={open ? `${name}-popover` : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        variant={contentVariant}
      >
        {content}
      </Typography>
      <Popover
        id={`${name}-popover`}
        open={open}
        sx={{
          pointerEvents: 'none',
        }}
        anchorEl={anchorEl}
        anchorOrigin={styles.anchorOrigin}
        transformOrigin={styles.transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{popoverContent}</Typography>
      </Popover>
    </Box>
  )
}
