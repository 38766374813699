// @ts-nocheck
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { setSignInSuccess, useAppController } from 'context'
import { feathersApp } from 'helpers'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Loading() {
  const navigate = useNavigate()
  const location = useLocation()
  const [, dispatch] = useAppController()

  useEffect(() => {
    feathersApp
      .reAuthenticate()
      .then(res => {
        console.log(res,"aaayayayayyaya");
        if (
          !res.user.roles ||
          (Array.isArray(res.user.roles) &&
            res.user.roles.indexOf('admin') === -1)
        ) {
          throw new Error('Unauthorized')
        }

        const from = location.state?.from?.pathname ?? '/'
        const search = location.state?.from?.search ?? null
        const fullPath = search != null ? `${from}${search}` : from

        setSignInSuccess(dispatch, {
          accessToken: res.accessToken,
          ...res.user,
        })
        if (from === '/admin-view/sign-in') {
          return navigate('/', { replace: true })
        }
        return navigate(fullPath, { replace: true })
      })
      .catch(err => {
        console.error(err)
        return navigate('/admin-view/sign-in', {
          replace: true,
        }),console.log("________________________________________________");
      })
  }, [dispatch, navigate])

  return (
    <MDBox pt={4} pb={3} px={3}>
      <MDBox display='flex' alignItems='center' justifyContent='center'>
        <MDTypography variant='h6'>Loading...</MDTypography>
      </MDBox>
    </MDBox>
  )
}
