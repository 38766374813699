import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Author } from 'components/GridItem'
import { getFileFullPath } from 'helpers'
import { useCallback, useMemo } from 'react'

export default function useData(
  result: Array<any> | null,
  handleOpenConfirmDlg: (service: string, value: string) => void,
  isAdmin: boolean,
) {
  const handleSetRole = useCallback(
    (value: string) => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg('roles', value)
    },
    [handleOpenConfirmDlg],
  )
  const handleDeactivate = useCallback(
    (value: string) => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg('status-deactive', value)
    },
    [handleOpenConfirmDlg],
  )

  const handleReactivate = useCallback(
    (id: string) => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg('status-reactivate', id)
    },
    [handleOpenConfirmDlg],
  )
  const renderAllUserActions = (item: any) => {
    return item.isDeactivated ? (
      <Button
        size='small'
        variant='text'
        className='ignoreReportBtn'
        onClick={handleReactivate(item._id)}
        sx={{ color: 'orange' }}
      >
        Re-activate
      </Button>
    ) : (
      <Button
        color={'error'}
        size='small'
        onClick={handleDeactivate(item._id)}
        variant='text'
        className='ignoreReportBtn'
        sx={{ color: 'red' }}
      >
        {'DEACTIVATE'}
      </Button>
    )
  }
  const renderAdminUserActions = (item: any) => {
    return (
      <Button
        color={item.roles?.length ? 'error' : 'primary'}
        size='small'
        onClick={handleSetRole(item._id)}
        variant='text'
        className='ignoreReportBtn'
        sx={{ color: item.roles?.length ? 'red' : null }}
      >
        {item.roles?.length ? 'REMOVE FROM ADMIN' : 'SET AS ADMIN'}
      </Button>
    )
  }
  return useMemo(() => {
    return {
      columns: [
        { Header: 'id', accessor: 'id', align: 'left' },
        { Header: 'user', accessor: 'user', align: 'left', width: 350 },
        { Header: 'email', accessor: 'email', align: 'center' },
        { Header: 'address', accessor: 'address', align: 'center' },
        { Header: 'roles', accessor: 'roles', align: 'right' },
        { Header: 'action', accessor: 'action', align: 'center' },
      ],
      rows: Array.isArray(result)
        ? result.map(r => ({
            id: <Typography variant='caption'>{r._id}</Typography>,
            user: (
              <Author
                image={getFileFullPath(r.profilePicture)}
                name={r.fullName}
                email={r.phone}
                isDeactivated={r.isDeactivated}
              />
            ),
            email: <Typography variant='caption'>{r.email}</Typography>,
            address: <Typography variant='caption'>{r.address}</Typography>,
            roles: (
              <Typography variant='caption'>{r.roles?.join(' ')}</Typography>
            ),
            action: !isAdmin
              ? renderAllUserActions(r)
              : renderAdminUserActions(r),
          }))
        : [],
    }
  }, [result, handleDeactivate, handleReactivate])
}
