import Button, { ButtonProps } from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import * as React from 'react'

export enum EMenuType {
  SELECT = 'SELECT',
  DEFAULT = 'DEFAULT',
}
export type TMenu = {
  label: string
  onClick: () => void
}
type TAppMenuProps = {
  buttonProps: { label: string } & ButtonProps
  menuProps: { menus: TMenu[] } & Omit<MenuProps, 'open'>
  type?: EMenuType
}

const ButtonStyle = styled(Button)({
  color: 'black',
  textTransform: 'initial',

  '&:hover': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
    color: 'white',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
    color: 'white',
  },
})

export function AppMenu(props: TAppMenuProps) {
  const { buttonProps, menuProps, type = EMenuType.DEFAULT } = props
  const { label, ...btnProps } = buttonProps
  const { menus, ...mnProps } = menuProps
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [labelState, setLabelState] = React.useState(label)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (menu: TMenu) => {
    menu.onClick()
    type === EMenuType.SELECT && setLabelState(menu.label)
    setAnchorEl(null)
  }
  return (
    <div>
      <ButtonStyle
        aria-controls={open ? btnProps['aria-controls'] : undefined}
        aria-haspopup='true'
        variant='outlined'
        style={{
          boxShadow: open ? 'none' : undefined,
          backgroundColor: open ? '#0062cc' : undefined,
          borderColor: open ? '#005cbf' : undefined,
          color: open ? 'white' : undefined,
        }}
        aria-expanded={open ? btnProps['aria-expanded'] : undefined}
        onClick={handleClick}
        {...btnProps}
      >
        {labelState}
      </ButtonStyle>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        {...mnProps}
      >
        {menus.map((m, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose(m)
            }}
          >
            {m.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
