import { Query } from '@feathersjs/client'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import ConfirmationDialog from 'components/ConfirmDialog'
import { UserDetailPopup } from 'components/UserDetailPopup/UserDetails'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DataTable from 'examples/Tables/DataTable'
import { escapeRegExp, feathersApp } from 'helpers'
import { useQuery } from 'helpers/hooks'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useAsyncDebounce } from 'react-table'

import { DialogApi } from '../../components/Diaglog/DialogHandler'
import userTableData from './data/userTableData'
import { FilterUserDetail } from './FilterUserDetail'

export const userService = feathersApp.service('users');



type UserManagementProps = {
  defaultQuery: Query
  enableRemoveClient: boolean
  onSearchChangeQuery: (value: string, prev?: Query) => Query
  onChangeFilterQuery?: (key: string, value: any, prev?: Query) => Query
  onChangePaginationQuery?: (limit: number, skip: number, prev?: Query) => Query
  isAdmin?: boolean
}

const defaultQueryValue: Query = {
  $skip: 0,
  $limit: 50,
  $sort: {
    createdAt: -1,
  },
}

const onSearchChangeQueryValue = (value: string, prev?: Query) => {
  return {
    query: {
      ...prev.query,
      $or:
        value && value.length > 0
          ? [
              {
                phone: { $regex: escapeRegExp(value) },
              },
              {
                email: { $regex: escapeRegExp(value) },
              },
              {
                fullName: { $regex: escapeRegExp(value), $options: 'i' },
              },
            ]
          : undefined,
    },
  }
}
const onFilterChangeQueryValue = (key: string, value: any, prev?: Query) => {
  return {
    query: {
      ...prev.query,
      [key]: value,
    },
  }
}
const onPaginationChangeQueryValue = (
  limit: number,
  skip: number,
  prev?: Query,
) => {
  return {
    query: {
      ...prev.query,
      $skip: skip,
      $limit: limit,
    },
  }
}
const defaultProps: UserManagementProps = {
  defaultQuery: defaultQueryValue,
  enableRemoveClient: false,
  onSearchChangeQuery: onSearchChangeQueryValue,
  onChangeFilterQuery: onFilterChangeQueryValue,
  onChangePaginationQuery: onPaginationChangeQueryValue,
}

function UserManagement({
  defaultQuery,
  enableRemoveClient,
  onSearchChangeQuery,
  onChangeFilterQuery,
  onChangePaginationQuery,
  isAdmin,
}: UserManagementProps) {
  const [showConfirmDlg, setShowConfirmDlg] = useState(false)
  const [dlgBody, setDlgBody] = useState('')
  const [dlgService, setDlgService] = useState<string | null>(null)
  const [dlgValue, setDlgValue] = useState<string | null>(null)
  const [query, setQuery] = useState<Query>({
    query: defaultQuery,
  })
  const gotoPageRef = useRef<Function>()

  const { fetching, error, result, setResult, total } = useQuery(
    'users2',
    0,
    50,
    query,
  )
  const handleOpenConfirmDlg = useCallback(
    (service: string, value: string) => {
      if (Array.isArray(result) && result.length) {
        const foundData = result.find(r => r._id === value)
        if (foundData) {
          if (service === 'status-reactivate') {
            setDlgBody('Are you sure you want to re-activate this user')
          } else if (service === 'roles') {
            if (foundData.roles?.length) {
              setDlgBody('Are you sure you want to un-set this user as Admin?')
            } else {
              setDlgBody('Are you sure you want to set this user as Admin?')
            }
          } else {
            setDlgBody('Are you sure you want to dectivate this user')
          }

          setDlgService(service)
          setDlgValue(value)
          setShowConfirmDlg(true)
        }
      }
    },
    [result],
  )

  const handleCloseDlg = useCallback(
    (service: string, value: string) => {
      if (Array.isArray(result) && result.length) {
        const foundIndex = result.findIndex(r => r._id === value)
        if (foundIndex > -1) {
          const foundData = result[foundIndex]
          let patchParams = {}
          if (service === 'status-reactivate') {
            patchParams = { isDeactivated: false }
          } else if (service === 'roles') {
            const roles = foundData.roles?.length ? [] : ['admin']
            patchParams = { roles }
          } else {
            patchParams = { isDeactivated: true }
          }
          userService.patch(value, patchParams).then(res => {
            setResult(prev => {
              if (enableRemoveClient) {
                return [
                  ...prev.slice(0, foundIndex),
                  ...prev.slice(foundIndex + 1),
                ]
              }

              return [
                ...prev.slice(0, foundIndex),
                { ...foundData, ...res },
                ...prev.slice(foundIndex + 1),
              ]
            })
          })
        }
      }

      setDlgService(null)
      setDlgValue(null)
      setDlgBody(null)
      setShowConfirmDlg(false)
    },
    [result],
  )

  const onSearchChange = useAsyncDebounce(value => {
    setQuery(prev => ({
      ...onSearchChangeQuery(value, prev),
    }))
    // gotoPageRef.current?.(0)
  }, 500)
  const onFilterChange = useAsyncDebounce((key: string, value: any) => {
    setQuery(prev => ({
      ...onChangeFilterQuery(key, value, prev),
    }))
    // gotoPageRef.current?.(0)
  }, 500)
  const onPaginationChange = useAsyncDebounce(
    (pageSize: number, pageIndex: number) => {
      setQuery(prev => ({
        ...onChangePaginationQuery(pageSize, pageSize * pageIndex, prev),
      }))
    },
    500,
  )
  const { columns, rows } = userTableData(result, handleOpenConfirmDlg, isAdmin)
  const handleRowClick = (key: string) => {
    const useSelected = result.find(r => r._id === key)
    DialogApi.show({
      component: () => <UserDetailPopup userId={useSelected._id} />,
      options: {
        onBackdropClick: () => {
          DialogApi.close()
        },
        maxWidth: 'xs',
        sx: {
          '& .MuiDialog-paper': { width: '50%', maxWidth: 300, maxHeight: 700 },
        },
      },
    })
  }
  const pageCount = useMemo(() => {
    if (total) {
      const limit = query.query?.['$limit'] || 50
      return Math.ceil(total / limit)
    }
    return 0
  }, [total, query.query['$limit']]);

  console.log("result users",result);
  return (
    <DashboardLayout>
      <Box pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  onRowClick={handleRowClick}
                  noEndBorder
                  pagination={{ pageCount, total }}
                  isSorted={false}
                  secondTopElement={
                    !isAdmin ? (
                      <FilterUserDetail
                        query={query}
                        users={result}
                        changeQuery={onFilterChange}
                      />
                    ) : null
                  }
                  showTotalEntries
                  canSearch
                  canSearchLabel={
                    'Find by ' +
                    (isAdmin ? 'exact phone number' : 'name / phone number')
                  }
                  fetching={fetching}
                  error={error}
                  rowkeyId='id'
                  onSearchChange={onSearchChange}
                  onPaginationChange={onPaginationChange}
                  gotoPageRef={gotoPageRef}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationDialog
        title={'Info'}
        body={dlgBody}
        onClose={handleCloseDlg}
        open={showConfirmDlg}
        service={dlgService}
        value={dlgValue}
      />
    </DashboardLayout>
  )
}

UserManagement.defaultProps = defaultProps

export default memo(UserManagement)
