import CloseIcon from '@mui/icons-material/Close'
import { Button, DialogActions, IconButton, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import colors from 'assets/theme-dark/base/colors'
import React, { useMemo, useState } from 'react'

import { DialogApi } from '../../components/Diaglog/DialogHandler'
import {
  AppRadioGroup,
  TRadioData,
} from '../../components/RadioGroup/RadioGroup'
import { convertAgeToDate, convertDateToAge } from '../../helpers/user'
import { ButtonStyle, DialogTitleCustom, Label } from './styles'

const filtersBy: TRadioData[] = [
  {
    label: '> greater than',
    value: '$lt',
    itemProps: { style: { flex: 1 } },
  },
  {
    label: '= equal',
    value: '',
    itemProps: { style: { flex: 1 } },
  },
  {
    label: '< less than',
    value: '$gt',
    itemProps: { style: { flex: 1 } },
  },
]

type TValue = string | { [key: string]: string }

function SelectAgePopup(props: {
  age?: string
  filterType: string
  onChangeValue: (value: any) => void
}) {
  const { age, filterType, onChangeValue } = props

  const [filterSelected, setFilterSelected] = useState(filterType)
  const [currentAge, setCurrentAge] = useState(age)
  const handleChangeAgeQuery = () => {
    DialogApi.close()
    if (!currentAge?.length) {
      return
    }
    const dateOfBirth = convertAgeToDate(parseInt(currentAge))
    let value: any = dateOfBirth
    if (filterSelected) {
      value = {
        [filterSelected]: dateOfBirth.toJSON(),
      }
    } else {
      value = {
        $lte: dateOfBirth.toJSON(),
        $gte: new Date(dateOfBirth.getFullYear(), 0, 0, 0, 0).toJSON(),
      }
    }
    onChangeValue(value)
  }
  const resetQueryBirthday = () => {
    onChangeValue(undefined)
    DialogApi.close()
  }
  return (
    <Box
      bgcolor='white'
      position='relative'
      color={colors.dark.main}
      flexDirection={'column'}
      p={1}
    >
      <Box display='flex'>
        <DialogTitleCustom>Select age</DialogTitleCustom>
        <IconButton onClick={() => DialogApi.close()} size='medium'>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box flex={1} flexDirection={'column'} display='flex' px={2}>
        <AppRadioGroup
          labelProps={{
            style: { fontSize: 14, color: 'black', fontWeight: 500 },
          }}
          row
          value={filterSelected}
          onChange={(e, value) => setFilterSelected(value)}
          datas={filtersBy}
          label='Filter:'
        />
        <Box flexDirection={'column'} gap={1} display='flex'>
          <Label>Age:</Label>
          <TextField
            value={currentAge}
            type='number'
            onChange={e => setCurrentAge(e.target.value)}
          />
        </Box>
      </Box>
      <DialogActions>
        <Button autoFocus onClick={() => DialogApi.close()}>
          Cancel
        </Button>
        <Button autoFocus onClick={resetQueryBirthday}>
          Reset
        </Button>
        <Button onClick={handleChangeAgeQuery} sx={{ color: 'red' }}>
          Ok
        </Button>
      </DialogActions>
    </Box>
  )
}

export const SelectAgeButton: React.FC<{
  value: TValue
  onChangeValue: (value: any) => void
}> = ({ value, onChangeValue }) => {
  const age = useMemo(() => {
    const keysOfValue = Object.keys(value)
    if (value && keysOfValue.length > 0) {
      const dateString = value[keysOfValue[0]]
      return dateString ? convertDateToAge(dateString) : ''
    }
    return ''
  }, [value])
  const filterType = useMemo(() => {
    const keysOfValue = Object.keys(value)
    if (value && keysOfValue.length === 1) {
      return keysOfValue[0]
    }
    return ''
  }, [value])
  const filterTypeValue = filterType ? (filterType === '$gt' ? '>' : '<') : ''
  return (
    <ButtonStyle
      aria-haspopup='true'
      variant='outlined'
      onClick={() =>
        DialogApi.show({
          component: () => (
            <SelectAgePopup
              age={age + ''}
              filterType={filterType}
              onChangeValue={onChangeValue}
            />
          ),
          options: {
            onBackdropClick: () => {
              DialogApi.close()
            },
            maxWidth: 'xs',
            sx: {
              '& .MuiDialog-paper': {
                width: '50%',
                maxWidth: 500,
                maxHeight: 700,
              },
            },
          },
        })
      }
      startIcon={
        <span style={{ fontSize: 14, fontWeight: 'normal' }}>Age:</span>
      }
    >
      {age ? filterTypeValue + age : 'All'}
    </ButtonStyle>
  )
}
