// @ts-nocheck
import LogoutIcon from '@mui/icons-material/Logout'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import {
  setMiniSidenav,
  setSignOut,
  setTransparentSidenav,
  setWhiteSidenav,
  useAppController,
  useMaterialUIController,
} from 'context'
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse'
import SidenavRoot from 'examples/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav'
import { feathersApp } from 'helpers'
import { signOutFirebase } from 'helpers/firebase'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'

function Sidenav({ color, brandName, routes, ...rest }) {
  const [, appDispatch] = useAppController()
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller
  const navigate = useNavigate()
  const location = useLocation()
  const collapseName = location.pathname

  const closeSidenav = () => setMiniSidenav(dispatch, true)

  const textColor = useMemo(() => {
    if (transparentSidenav || (whiteSidenav && !darkMode)) {
      return 'dark'
    } else if (whiteSidenav && darkMode) {
      return 'inherit'
    }

    return 'white'
  }, [transparentSidenav, whiteSidenav, darkMode])

  const handleSignOut = useCallback(() => {
    Promise.all([signOutFirebase(), feathersApp.logout()]).then(() => {
      setSignOut(appDispatch)
      navigate('/admin-view/sign-in', { replace: true })
    })
  }, [appDispatch, navigate])

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200)
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav,
      )
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav)
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav)

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav)
  }, [dispatch, location])

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue

      if (type === 'collapse') {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target='_blank'
            rel='noreferrer'
            sx={{ textDecoration: 'none' }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={route === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={route === collapseName}
            />
          </NavLink>
        )
      } else if (type === 'title') {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display='block'
            variant='caption'
            fontWeight='bold'
            textTransform='uppercase'
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        )
      } else if (type === 'divider') {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        )
      }

      return returnValue
    },
  )

  return (
    <>
      <SidenavRoot
        {...rest}
        variant='permanent'
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={4} textAlign='center'>
          <MDBox
            display={{ xs: 'block', xl: 'none' }}
            position='absolute'
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: 'pointer' }}
          >
            <MDTypography variant='h6' color='secondary'>
              <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox component={NavLink} to='/' display='flex' alignItems='center'>
            <MDBox
              width={!brandName && '100%'}
              sx={theme => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <MDTypography
                component='h6'
                variant='button'
                fontWeight='medium'
                color={textColor}
              >
                {brandName}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List>{renderRoutes}</List>
        <MDBox p={2} mt='auto'>
          <Button
            startIcon={<LogoutIcon />}
            onClick={handleSignOut}
            sx={{ color: 'white' }}
          >
            Sign Out
          </Button>
        </MDBox>
      </SidenavRoot>
      <Outlet />
    </>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav
