import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { DialogApi } from 'components/Diaglog/DialogHandler'
import { Author, PopoverItem, ReportedItem } from 'components/GridItem'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import { DATE_DDMMYYYY_HH_MM_SSS, getFileFullPath } from 'helpers'
import { useCallback, useMemo } from 'react'

import { UserDetailPopup } from '../../../components/UserDetailPopup/UserDetails'

export default function useData(
  result: Array<any> | null,
  handleOpenConfirmDlg: (service: string, value: string) => void,
) {
  const today = useMemo(() => new Date(), [])
  const handleClick = useCallback(
    (service: string, value: string) => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg(service, value)
    },
    [],
  )
  return useMemo(() => {
    return {
      columns: [
        { Header: 'id', accessor: 'id', align: 'left' },
        { Header: 'user', accessor: 'reportedUser', align: 'left', width: 450 },
        { Header: 'reason', accessor: 'content', align: 'center' },
        { Header: 'reported by', accessor: 'userId', align: 'right' },
        { Header: 'action', accessor: 'action', align: 'center' },
      ],
      rows: Array.isArray(result)
        ? result.map(r => ({
            id: <Typography variant='caption'>{r._id}</Typography>,
            reportedUser: (
              <ReportedItem
                image={
                  getFileFullPath(r.reportedUserData?.profilePicture) ?? ''
                }
                title={r.reportedUserData?.fullName}
                subTitle={r.reportedUserData?.phone}
                onDeleteLabel='Deactivate User'
                onDeleteAction={handleClick('users', r._id)}
                onClick={() =>
                  DialogApi.show({
                    component: () => (
                      <UserDetailPopup userId={r.reportedUserData._id} />
                    ),

                    options: {
                      onBackdropClick: () => {
                        DialogApi.close()
                      },
                      maxWidth: 'xs',
                      sx: {
                        '& .MuiDialog-paper': {
                          width: '50%',
                          maxWidth: 300,
                          maxHeight: 700,
                        },
                      },
                    },
                  })
                }
              />
            ),
            content: (
              <PopoverItem
                name={r._id}
                contentVariant='caption'
                content={`${r.content} - ${formatRelative(
                  new Date(r.createdAt),
                  today,
                )}`}
                popoverContent={format(
                  new Date(r.createdAt),
                  DATE_DDMMYYYY_HH_MM_SSS,
                )}
              />
            ),
            userId: (
              <Author
                image={getFileFullPath(r.user?.profilePicture)}
                name={r.user?.fullName}
                email={r.user?.phone}
                handleOnClick={() =>
                  DialogApi.show({
                    component: () => (
                      <UserDetailPopup userId={r.user?._id || ''} />
                    ),
                    options: {
                      onBackdropClick: () => {
                        DialogApi.close()
                      },
                      maxWidth: 'xs',
                      sx: {
                        '& .MuiDialog-paper': {
                          width: '50%',
                          maxWidth: 300,
                          maxHeight: 700,
                        },
                      },
                    },
                  })
                }
              />
            ),
            action: (
              <>
                <Button
                  color='primary'
                  size='small'
                  onClick={handleClick('user-reports', r._id)}
                  variant='text'
                  className='ignoreReportBtn'
                >
                  Ignore Report
                </Button>
              </>
            ),
          }))
        : [],
    }
  }, [result, handleClick])
}
