import { Query } from '@feathersjs/client'
import { Box } from '@mui/material'
import { escapeRegExp } from 'helpers'
import React, { useMemo } from 'react'

import { AppMenu, EMenuType, TMenu } from '../../components/Menu/Menu'
import { Country, IContry } from '../../helpers/data/country'
import { ExportButton } from './ExportButton'
import { SelectAgeButton } from './SelectAge'

type TProps = {
  query: Query
  users: any
  changeQuery: (key: string, value: any) => void
}

export const FilterUserDetail: React.FC<TProps> = ({
  changeQuery,
  query,
  users,
}) => {
  const contries: TMenu[] = useMemo(() => {
    const data: IContry[] = Country.getAll()
    if (!data.find(i => i.dialCode === '-1')) {
      data.unshift({
        areaCodes: null,
        dialCode: '-1',
        iso2: '',
        name: 'All',
        priority: 0,
      })
    }
    return data.map((c): TMenu => {
      const country = '+' + c.dialCode
      return {
        label: c.name,
        onClick: () =>
          changeQuery(
            'phone',
            parseInt(c.dialCode) !== -1
              ? { $regex: escapeRegExp(country) }
              : undefined,
          ),
      }
    })
  }, [])
  const onlineStatus: TMenu[] = useMemo(() => {
    const onlineStatusData: TMenu[] = [
      {
        label: 'All',
        onClick: () => changeQuery('isOnline', undefined),
      },
      {
        label: 'Online',
        onClick: () => changeQuery('isOnline', true),
      },
      {
        label: 'Offline',
        onClick: () => changeQuery('isOnline', false),
      },
    ]
    return onlineStatusData
  }, [])

  const status: TMenu[] = useMemo(() => {
    const status: TMenu[] = [
      {
        label: 'All',
        onClick: () => changeQuery('isDeactivated', undefined),
      },
      {
        label: 'Deactivated',
        onClick: () => changeQuery('isDeactivated', true),
      },
    ]
    return status
  }, [])

  return (
    <>
      <AppMenu
        buttonProps={{
          label: contries[0].label,
          startIcon: (
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>Country:</span>
          ),
        }}
        type={EMenuType.SELECT}
        menuProps={{
          menus: contries,
          PaperProps: {
            style: {
              maxHeight: 500,
            },
          },
        }}
      />

      <AppMenu
        buttonProps={{
          label: contries[0].label,
          startIcon: (
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>
              Online Status:
            </span>
          ),
        }}
        type={EMenuType.SELECT}
        menuProps={{
          menus: onlineStatus,
        }}
      />

      <AppMenu
        buttonProps={{
          label: contries[0].label,
          startIcon: (
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>Status:</span>
          ),
        }}
        type={EMenuType.SELECT}
        menuProps={{
          menus: status,
        }}
      />
      <SelectAgeButton
        value={query.query['birthday'] || ''}
        onChangeValue={(value: any) =>
          changeQuery('birthday', value ? value : undefined)
        }
      />
      <ExportButton query={query} />
    </>
  )
}
