// @ts-nocheck
import feathers from '@feathersjs/client'
import { baseUrl } from 'helpers/constants'
import io from 'socket.io-client'

// Socket.io is exposed as the `io` global.
const socket = io(baseUrl)
console.log(socket,"connected");
// @feathersjs/client is exposed as the `feathers` global.
const feathersApp = feathers()

feathersApp.configure(feathers.socketio(socket))
feathersApp.configure(
  feathers.authentication({
    storage: window.localStorage,
  }),
)

const eventService = feathersApp.service('events');
console.log("eventService",eventService);
const eventReportService = feathersApp.service('event-reports')
const feedService = feathersApp.service('feeds')
const feedReportService = feathersApp.service('feed-reports')

// eslint-disable-next-line import/prefer-default-export
export {
  eventReportService,
  eventService,
  feathersApp,
  feedReportService,
  feedService,
}
