// @ts-nocheck
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppController } from 'context'

export default function Home() {
  const [appController] = useAppController()
  const { authInfo } = appController
  return (
    <MDBox pt={4} pb={3} px={3}>
      <MDBox display='flex' alignItems='center' justifyContent='center'>
        <MDTypography variant='h6'>Hello {authInfo.fullName}</MDTypography>
      </MDBox>
    </MDBox>
  )
}
