import './App.css'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'assets/theme'
import themeDark from 'assets/theme-dark'
import NotFound from 'components/NotFound'
import {
  setMiniSidenav,
  useAppController,
  useMaterialUIController,
} from 'context'
import Sidenav from 'examples/Sidenav'
import Home from 'layouts/home'
import Loading from 'layouts/loading'
import { useCallback, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { AppDialog } from './components/Diaglog/AppDialog'
import routes, { AppRoute } from './routes'

function AuthVerification({ children }: { children: any }) {
  const [appController] = useAppController()
  const { hasSignedIn } = appController
  const location = useLocation()

  if (!hasSignedIn) {
    return <Navigate to='/loading' state={{ from: location }} replace />
  }

  return children
}

const privateRoutes = routes.filter(r => r.private)
const publicRoutes = routes.filter(r => !r.private)

export default function App() {
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, direction, sidenavColor, darkMode } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const { pathname } = useLocation()

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0
    }
  }, [pathname])

  const getRoutes = useCallback(
    (_allRoutes: AppRoute) =>
      _allRoutes.map(route => {
        if (route.authVerification) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={<AuthVerification>{route.component}</AuthVerification>}
            />
          )
        }

        return (
          <Route key={route.key} path={route.route} element={route.component} />
        )
      }),
    [],
  )

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Routes>
        <Route
          path='/'
          element={
            <Sidenav
              color={sidenavColor}
              brandName='Whuups Admin'
              routes={privateRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          }
        >
          <Route
            index
            element={
              <AuthVerification>
                <Home />
              </AuthVerification>
            }
          />
          {getRoutes(privateRoutes)}
        </Route>
        {getRoutes(publicRoutes)}
        <Route path='/loading' element={<Loading />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<Navigate replace to='/404' />} />
      </Routes>
      <AppDialog />
    </ThemeProvider>
  )
}
