import Report, { ReportModelConfig } from 'examples/LayoutContainers/Reports'
import { getFileFullPath } from 'helpers'
import useEventReportData from 'layouts/event-reports/data/eventReportTableData'
import { useCallback } from 'react'

const ModelConfig: ReportModelConfig = {
  model: 'event-reports',
  modelDisplay: 'event',
  reportedModel: 'events',
  fields: {
    title: 'title',
    body: 'about',
    image: 'images',
    reportedId: 'eventId',
    reportedBy: 'user',
    reportedReason: 'content',
    reportedModel: 'event',
  },
}

function Events() {
  const getEventImageFullPath = useCallback(
    images =>
      Array.isArray(images) && images.length
        ? getFileFullPath(images[0])
        : null,
    [],
  )

  return (
    <Report
      config={ModelConfig}
      useReportData={useEventReportData}
      getReportImageFullPath={getEventImageFullPath}
    />
  )
}

export default Events
