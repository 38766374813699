import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import profileUrl from 'assets/images/profile.jpg'
import colors from 'assets/theme-dark/base/colors'
import format from 'date-fns/format'
import { DATE_DDMMYYYY } from 'helpers/constants'
import React, { useMemo } from 'react'

import { getFileFullPath } from '../../helpers'
import { useQuery } from '../../helpers/hooks'
import { getWrapperFullPath } from '../../helpers/user'
import { DeactivateButton } from '../../layouts/users/DeactivateButton'
import { ExportButton } from '../../layouts/users/ExportButton'
import { DialogApi } from '../Diaglog/DialogHandler'

const StyledBadge = styled(Badge)(({ theme, componentsProps }) => ({
  '& .MuiBadge-badge': {
    width: 20,
    height: 20,
    borderRadius: 50,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const UserDetailPopup: React.FC<{ userId: string }> = ({ userId }) => {
  const queryParams = useMemo(() => {
    return {
      query: {
        _id: userId,
        $limit: 1,
      },
    }
  }, [userId])
  const { fetching, error, result, setResult } = useQuery(
    'users2',
    0,
    50,
    queryParams,
  )

  const user = result ? result[0] : null
  const image = getFileFullPath(user?.profilePicture)
  const wrapperImage = getWrapperFullPath(user?.wallpaperBackground)

  const _renderField = (label: string, field: any) => (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Typography
        display='block'
        variant='inherit'
        fontSize={14}
        fontWeight='medium'
        marginRight={1}
      >
        {label}:
      </Typography>
      <Typography variant='caption' fontStyle={field ? undefined : 'italic'}>
        {field || 'Empty'}
      </Typography>
    </Box>
  )

  return (
    <Box
      bgcolor='white'
      position='relative'
      color={colors.dark.main}
      flexDirection={'column'}
      padding={2}
    >
      {fetching ? (
        <Box
          minHeight={300}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color='info' />
        </Box>
      ) : (
        <>
          <Box
            style={{ cursor: 'pointer' }}
            position={'absolute'}
            top={8}
            right={8}
          >
            <IconButton onClick={() => DialogApi.close()} size='medium'>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            lineHeight={1}
            flexDirection={'column'}
            display='flex'
            alignItems={'center'}
            style={{
              backgroundImage: `url(${wrapperImage})`,
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <StyledBadge
              color={user?.isOnline ? 'success' : 'error'}
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <Avatar
                sx={{ width: 90, height: 90 }}
                src={image || profileUrl}
              />
            </StyledBadge>
          </Box>
          <Box display='flex' flexDirection='column' mt={2}>
            {user?.isDeactivated && (
              <Typography
                variant='caption'
                fontWeight='medium'
                sx={{ color: 'orange' }}
              >
                Deactivated
              </Typography>
            )}
            {_renderField('Full Name', user?.fullName)}
            {_renderField(
              'Birthday',
              user?.birthday &&
                format(new Date(user?.birthday || ''), DATE_DDMMYYYY),
            )}
            {_renderField('Phone', user?.phone)}
            {_renderField('Language', user?.lang)}
            {_renderField('Platform', user?.platform)}
            {_renderField('Email', user?.email)}
            {_renderField('Address', user?.address)}
            {_renderField(
              'Hoobies',
              user?.hobbies?.length ? user.hobbies.join(', ') : '',
            )}
            {_renderField('Status', user?.status)}
            {_renderField('Color Theme', user?.colorTheme)}
            <Box
              display='flex'
              mt={1}
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              gap={2}
            >
              <ExportButton users={result} />
              <DeactivateButton
                cb={data => setResult([data])}
                userInfo={user}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
