// @ts-nocheck
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
// Material Dashboard 2 React context
import { setLayout, useMaterialUIController } from 'context'
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'
import { useEffect } from 'react'
// react-router-dom components
import { useLocation } from 'react-router-dom'

function PageLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController()
  const { pathname } = useLocation()

  useEffect(() => {
    setLayout(dispatch, 'page')
  }, [dispatch, pathname])

  return (
    <MDBox
      width='100vw'
      height='100%'
      minHeight='100vh'
      bgColor={background}
      sx={{ overflowX: 'hidden' }}
    >
      {children}
    </MDBox>
  )
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default',
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
}

export default PageLayout
