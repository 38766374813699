import Report, { ReportModelConfig } from 'examples/LayoutContainers/Reports'

import useFeedReportData from './data/feedCommentReportTableData'

const ModelConfig: ReportModelConfig = {
  model: 'feed-comment-reports',
  modelDisplay: 'talk comment',
  reportedModel: 'feedComment',
  fields: {
    title: 'title',
    body: 'description',
    image: 'image',
    reportedId: 'feedCommentId',
    reportedBy: 'user',
    reportedReason: 'content',
    reportedModel: 'feedComment',
  },
}

function FeedComments() {
  return <Report config={ModelConfig} useReportData={useFeedReportData} />
}

export default FeedComments
