import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Author, PopoverItem, ReportedItem } from 'components/GridItem'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import { DATE_DDMMYYYY_HH_MM_SSS, getFileFullPath } from 'helpers'
import { useCallback, useMemo } from 'react'

import { DialogApi } from '../../../components/Diaglog/DialogHandler'
import { UserDetailPopup } from '../../../components/UserDetailPopup/UserDetails'

export default function useData(
  result: Array<any> | null,
  handleOpenConfirmDlg: (service: string, value: string) => void,
) {
  const handleClick = useCallback(
    (service: string, value: string) => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg(service, value)
    },
    [],
  )

  const today = useMemo(() => new Date(), [])

  return useMemo(() => {
    return {
      columns: [
        { Header: 'id', accessor: '_id', align: 'left' },
        {
          Header: 'talk comment',
          accessor: 'feedCommentId',
          align: 'left',
          width: 450,
        },
        { Header: 'reason', accessor: 'content', align: 'center' },
        { Header: 'reported by', accessor: 'userId', align: 'right' },
        { Header: 'action', accessor: 'action', align: 'center' },
      ],
      rows: Array.isArray(result)
        ? result.map(r => {
            const contents = r?.feedReply?.content.split(
              ' !@#$whuups-comment-2022',
            )
            if (r?.type === 'image' && contents.length === 1) {
              contents.unshift('')
            }
            return {
              _id: <Typography variant='caption'>{r._id}</Typography>,
              feedCommentId: (
                <ReportedItem
                  image={getFileFullPath(contents[1]) ?? ''}
                  subTitle={contents[0]}
                  title={r?.feedReply?.userId?.fullName}
                  onDeleteLabel={'Delete Reply'}
                  onDeleteAction={handleClick('feedReply', r._id)}
                  onClick={() =>
                    DialogApi.show({
                      component: () => (
                        <UserDetailPopup userId={r?.feedReply?.userId?._id} />
                      ),

                      options: {
                        onBackdropClick: () => {
                          DialogApi.close()
                        },
                        maxWidth: 'xs',
                        sx: {
                          '& .MuiDialog-paper': {
                            width: '50%',
                            maxWidth: 300,
                            maxHeight: 700,
                          },
                        },
                      },
                    })
                  }
                />
              ),
              content: (
                <PopoverItem
                  name={r._id}
                  contentVariant='caption'
                  content={`${r.content} - ${formatRelative(
                    new Date(r.createdAt),
                    today,
                  )}`}
                  popoverContent={format(
                    new Date(r.createdAt),
                    DATE_DDMMYYYY_HH_MM_SSS,
                  )}
                />
              ),
              userId: (
                <Author
                  image={getFileFullPath(r.user?.profilePicture)}
                  name={r.user?.fullName}
                  email={r.user?.phone}
                  handleOnClick={() =>
                    DialogApi.show({
                      component: () => <UserDetailPopup userId={r.user._id} />,
                      options: {
                        onBackdropClick: () => {
                          DialogApi.close()
                        },
                        maxWidth: 'xs',
                        sx: {
                          '& .MuiDialog-paper': {
                            width: '50%',
                            maxWidth: 300,
                            maxHeight: 700,
                          },
                        },
                      },
                    })
                  }
                />
              ),
              action: (
                <>
                  <Button
                    color='primary'
                    size='small'
                    onClick={handleClick('feed-comment-reports', r._id)}
                    variant='text'
                    className='ignoreReportBtn'
                  >
                    Ignore Report
                  </Button>
                </>
              ),
            }
          })
        : [],
    }
  }, [result, handleClick])
}
