import { Query } from '@feathersjs/client'
import { Box, LinearProgress, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import { DialogApi } from '../../components/Diaglog/DialogHandler'
import { feathersApp } from '../../helpers'

export const ProgressExport: React.FC<{
  exportTo: (users: any, type: number) => void
  type: number
  query: Query
  exportFail: () => void
}> = ({ exportTo, type, query, exportFail }) => {
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)
  const service = useRef(feathersApp.service('users2'))
  const userLength = users.length

  useEffect(() => {
    exportAll()
  }, [])

  useEffect(() => {
    if (!!userLength && userLength === total) {
      DialogApi.close()
      exportTo(users, type)
    }
  }, [users])

  const exportSuccess = res => {
    !total && setTotal(res.total)
    const newSkip = res.skip + 200
    res.data.forEach(item => {
      delete item.apnDeviceToken
      delete item.blockedUsers
      delete item.channels
      delete item.deviceToken
      delete item.password
      delete item.profilePicture
      delete item.roles
      delete item.wallpaperBackground
      delete item.createdAt
      delete item.updatedAt
      delete item.__v
    })
    setUsers(prev => {
      return [...prev].concat(res.data)
    })
    if (newSkip < res.total) {
      exportAll(newSkip)
    }
  }

  const exportAll = (skip = 0) => {
    service.current
      .find({
        query: { ...query.query, $skip: skip, $limit: 200 },
      })
      .then(res => exportSuccess(res))
      .catch(err => exportFail())
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      bgcolor='white'
      position='relative'
      height={150}
      p={2}
    >
      <Typography variant='h5' color='info' textAlign='center'>
        Progress Exporting...
      </Typography>
      <Box flex='1'></Box>
      <Box overflow='hidden' width='100%' alignItems='center' flex='1'>
        <LinearProgress variant='indeterminate' color='primary' />
      </Box>
      {!!total && (
        <Typography variant='body1' color='text.secondary'>
          {userLength} / {total}
        </Typography>
      )}
    </Box>
  )
}
