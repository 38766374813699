import { uploadUrl, wrapperUrl } from './constants'

export const getFileFullPath = (path?: string) => {
  path = getSmallerImage(path)
  return path ? `${uploadUrl}/${path}` : null
}

export const getWrapperFullPath = (path?: string) => {
  return path ? `${wrapperUrl}/${path}` : null
}

export const getSmallerImage = (url?: string) => {
  if (!url || url.indexOf('.w300.') >= 0) {
    return url
  }
  const re = /\.((jpg)|(jpeg)|(png))$/i
  if (!re.test(url)) {
    return url
  }
  return url.replace(re, '.w300.$1')
}

export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
export const convertAgeToDate = (age: number): Date => {
  const now = new Date()
  const dateOfBirth = new Date(
    now.getFullYear() - age,
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0,
  )
  return dateOfBirth
}
export const convertDateToAge = (date: string): number => {
  const today = new Date()
  const birthDate = new Date(date)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}
export const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string
  fileName: string
  fileType: string
}) => {
  const blob = new Blob([data], { type: fileType })

  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}
