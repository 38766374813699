import Report, { ReportModelConfig } from 'examples/LayoutContainers/Reports'

import useFeedReportData from './data/feedCommentReportTableData'

const ModelConfig: ReportModelConfig = {
  model: 'feed-reply-reports',
  modelDisplay: 'talk reply ',
  reportedModel: 'feedRelyComment',
  fields: {
    title: 'title',
    body: 'description',
    image: 'image',
    reportedId: 'feedReplyId',
    reportedBy: 'user',
    reportedReason: 'content',
    reportedModel: 'feedReply',
  },
}

function FeedReplies() {
  return <Report config={ModelConfig} useReportData={useFeedReportData} />
}

export default FeedReplies
