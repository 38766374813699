// @ts-nocheck
import { TableCell } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MDPagination from 'components/MDPagination'
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell'
import DataTableHeadCell from 'examples/Tables/DataTable/DataTableHeadCell'
import PropTypes from 'prop-types'
import React, {
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

type DataTableProps = {
  table: { columns: Array<any>; rows: Array<any> }
  canSearch?: boolean
  canSearchLabel?: string
  isSorted?: boolean
  entriesPerPage: { defaultValue?: number; entries?: Array<number> }
  showTotalEntries?: boolean
  noEndBorder?: boolean
  pagination?: {
    variant?: string
    color?: string
    pageCount?: number
    total?: number
  }
  rowkeyId: string
  fetching?: boolean
  error?: any
  onPaginationChange?: (pageSize: number, pageIndex: number) => void
  multipleActions?: any[]
  secondTopElement?: React.ReactElement
  onRowClick?: (rowKeyIdValue: string) => any
  onSearchChange?: (value: string) => void
  gotoPageRef?: any
}

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  const handleClick = useCallback(
    event => {
      event?.stopPropagation()
      rest.onClick && rest.onClick(event)
    },
    [rest.onClick],
  )

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input
        type='checkbox'
        ref={resolvedRef}
        {...rest}
        onClick={handleClick}
      />
    </>
  )
})

function DataTable({
  entriesPerPage,
  canSearch,
  canSearchLabel,
  showTotalEntries,
  table,
  pagination = {
    pageCount: 0,
  },
  isSorted,
  noEndBorder,
  rowkeyId,
  fetching,
  error,
  multipleActions = [],
  secondTopElement,
  onRowClick,
  onSearchChange,
  onPaginationChange,
  gotoPageRef,
}: DataTableProps) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map(el => el.toString())
    : ['5', '10', '15', '20', '50']
  const columns = useMemo(() => table.columns, [table])
  const data = useMemo(() => table.rows, [table])

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: pagination.pageCount > 0,
      pageCount: pagination.pageCount,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      multipleActions.length &&
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
    },
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    previousPage,
    nextPage,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = tableInstance

  if (gotoPageRef) {
    gotoPageRef.current = gotoPage
  }

  const renderCount = useRef(0)
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue])

  useEffect(() => {
    if (renderCount.current > 0) {
      onPaginationChange?.(pageSize, pageIndex)
    }
  }, [pageIndex])
  useEffect(() => {
    renderCount.current += 1
  })

  useEffect(() => {
    allColumns.forEach(c => {
      if (c.id === rowkeyId) {
        c.toggleHidden()
      }
    })
  }, [allColumns, rowkeyId])
  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: number) => {
    setPageSize(value)
    gotoPage(0)
    onPaginationChange?.(value, 0)
  }

  // Render the paginations
  const renderPagination = pageOptions.map(option => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ))

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value))

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map(option => option + 1)

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1))

  // Search input state handle
  // const onSearchChange = useAsyncDebounce(value => {
  //   setGlobalFilter(value || undefined)
  // }, 100)

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onSearchChange(event.currentTarget.value)
    },
    [],
  )

  // A function that sets the sorted value for the table
  const setSortedValue = column => {
    let sortedValue
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce'
    } else if (isSorted) {
      sortedValue = 'none'
    } else {
      sortedValue = false
    }
    return sortedValue
  }

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1

  // Setting the entries ending point
  let entriesEnd = pageSize * (pageIndex + 1)
  if (pageIndex === pageOptions.length - 1) {
    entriesEnd = entriesEnd - pageSize + rows.length
  }

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {canSearch || secondTopElement ? (
        <Box
          display='flex'
          alignItems='center'
          gap={2}
          justifyContent='flex-end'
          p={3}
        >
          {canSearch && (
            <Box width='28rem' mr='auto'>
              <TextField
                placeholder={canSearchLabel}
                size='small'
                fullWidth
                onChange={handleTextChange}
              />
            </Box>
          )}
          {secondTopElement ? secondTopElement : null}
        </Box>
      ) : null}
      <Table {...getTableProps()}>
        <Box component='thead'>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps(),
                  )}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </Box>
        {fetching ? (
          <Box component='tbody'>
            <TableRow>
              <TableCell
                colSpan={columns.filter(c => c.accessor !== 'id').length}
              >
                <Typography variant='h6' color='info' textAlign='center'>
                  Loading...
                </Typography>
              </TableCell>
            </TableRow>
          </Box>
        ) : Boolean(error) ? (
          <Box component='tbody'>
            <TableRow>
              <TableCell
                colSpan={columns.filter(c => c.accessor !== 'id').length}
              >
                <Typography variant='h6' color='info' textAlign='center'>
                  {error.message}
                </Typography>
              </TableCell>
            </TableRow>
          </Box>
        ) : (
          <TableBody {...getTableBodyProps()}>
            {Array.isArray(selectedFlatRows) && selectedFlatRows.length > 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.filter(c => c.accessor !== 'id').length + 1}
                >
                  {multipleActions.map(el =>
                    cloneElement(
                      el,
                      {
                        ...el.props,
                        handleAction: el.props.handleAction(
                          selectedFlatRows.map(s => s.original),
                        ),
                      },
                      null,
                    ),
                  )}
                </TableCell>
              </TableRow>
            )}
            {page.map((row, key) => {
              prepareRow(row)
              return (
                <TableRow
                  {...row.getRowProps()}
                  hover
                  onClick={e => {
                    e?.stopPropagation()
                    onRowClick &&
                      onRowClick(row.values?.[rowkeyId]?.props?.children)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {row.cells.map(cell => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : 'left'}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>

      {entriesPerPage && (
        <Box display='flex' alignItems='center' p={3}>
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : 'gradient'}
              color={pagination.color ? pagination.color : 'info'}
            >
              {canPreviousPage && (
                <MDPagination item onClick={previousPage}>
                  <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <Box width='5rem' mx={1}>
                  <TextField
                    inputProps={{
                      type: 'number',
                      min: 1,
                      max: customizedPageOptions.length,
                    }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={
                      (handleInputPagination, handleInputPaginationValue)
                    }
                  />
                </Box>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={nextPage}>
                  <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
          {showTotalEntries && (
            <Box mb={{ xs: 3, sm: 0 }} ml='10px'>
              <Typography
                variant='button'
                color='secondary'
                fontWeight='regular'
              >
                Showing {entriesStart} to {entriesEnd} of {pagination.total}{' '}
                entries
              </Typography>
            </Box>
          )}
          {entriesPerPage && (
            <Box display='flex' alignItems='center' ml='auto'>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10))
                }}
                size='small'
                sx={{ width: '5rem', marginRight: '5px' }}
                renderInput={params => <TextField {...params} />}
              />
              <Typography variant='caption' color='secondary'>
                entries per page
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </TableContainer>
  )
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 50, entries: [5, 10, 15, 20, 50] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
}

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
}

export default memo(DataTable)
