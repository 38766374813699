import Report, { ReportModelConfig } from 'examples/LayoutContainers/Reports'
import userReportTableData from 'layouts/user-reports/data/userReportTableData'

const ModelConfig: ReportModelConfig = {
  model: 'user-reports',
  modelDisplay: 'user',
  reportedModel: 'users',
  fields: {
    title: 'fullName',
    body: 'address',
    image: 'profilePicture',
    reportedId: 'reportedUser',
    reportedBy: 'user',
    reportedReason: 'content',
    reportedModel: 'reportedUserData',
  },
}

function Users() {
  return <Report config={ModelConfig} useReportData={userReportTableData} />
}

export default Users
