import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import NoImage from 'assets/images/not-image.png'
import { getSmallerImage } from 'helpers/user'

type ReportedItemProps = {
  title?: string | null
  subTitle?: string | null
  image?: string
  onDeleteLabel?: string
  onDeleteAction?: (event: any) => void
  onClick?: () => void
}

export function ReportedItem({
  title,
  subTitle,
  image,
  onDeleteLabel,
  onDeleteAction,
  onClick,
}: ReportedItemProps) {
  return (
    <Box
      onClick={e => {
        e?.stopPropagation()
        onClick()
      }}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        lineHeight: 0,
      }}
    >
      <CardMedia
        component='img'
        sx={{
          width: 120,
          height: 90,
          border: '1px solid rgba(0, 0, 0, 0.04)',
          padding: 0,
          margin: 0,
        }}
        className='reportedItemImg'
        image={image ? getSmallerImage(image) : NoImage}
      />
      <CardContent className='reportedItemContent'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={{ whiteSpace: 'nowrap', width: 350 }}>
            <Box
              sx={{
                typography: 'h6',
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: 0,
                lineHeight: 1.5,
              }}
            >
              {title}
            </Box>
          </div>
        </Box>
        {Boolean(subTitle) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ whiteSpace: 'nowrap', width: 350 }}>
              <Box
                sx={{
                  typography: 'body',
                  color: 'GrayText',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: 0,
                  lineHeight: 1,
                }}
              >
                {subTitle}
              </Box>
            </div>
          </Box>
        )}
        <Button
          size='small'
          onClick={onDeleteAction}
          startIcon={<DeleteIcon style={{ position: 'relative', top: -1 }} />}
          className='reportedItemDeleteBtn'
        >
          {onDeleteLabel}
        </Button>
      </CardContent>
    </Box>
  )
}
