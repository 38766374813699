import { find, orderBy } from 'lodash'

let instance: any = null

export interface IContry {
  areaCodes: string | null
  dialCode: string
  iso2: string | null
  name: string
  priority: number
}
class CountryService {
  countryCodes: any
  countriesData: any
  countries: any
  static getInstance() {
    if (!instance) {
      instance = new CountryService()
    }
    return instance
  }

  constructor() {
    this.countryCodes = []

    this.countriesData = null
  }

  setCustomCountriesData(json: any) {
    this.countriesData = json
  }

  addCountryCode(iso2: any, dialCode: any, priority?: any) {
    if (!(dialCode in this.countryCodes)) {
      this.countryCodes[dialCode] = []
    }

    const index = priority || 0
    this.countryCodes[dialCode][index] = iso2
  }

  getAll() {
    if (!this.countries) {
      this.countries = orderBy(
        this.countriesData || require('./countries.json'),
        ['name'],
        ['asc'],
      )
    }

    return this.countries
  }

  getCountryCodes() {
    if (!this.countryCodes.length) {
      this.getAll().map((country: any) => {
        this.addCountryCode(country.iso2, country.dialCode, country.priority)
        if (country.areaCodes) {
          country.areaCodes.map((areaCode: any) => {
            this.addCountryCode(country.iso2, country.dialCode + areaCode)
          })
        }
      })
    }
    return this.countryCodes
  }

  getCountryDataByCode(iso2: any) {
    return find(this.getAll(), country => country.iso2 === iso2)
  }
}
const Country = CountryService.getInstance()
export { Country }
