import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useCallback } from 'react'

type ConfirmationDialogProps = {
  title: string
  body: string
  open: boolean
  service: string
  value: string | string[]
  onClose: (service?: string, value?: string | string[]) => any
}

export default function ConfirmationDialog({
  title,
  body,
  open,
  service,
  value,
  onClose,
  ...rest
}: ConfirmationDialogProps) {
  const handleCancel = useCallback(() => {
    onClose()
  }, [])

  const handleOk = useCallback(() => {
    onClose(service, value)
  }, [service, value])

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      {...rest}
      onBackdropClick={handleCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk} sx={{ color: 'red' }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
