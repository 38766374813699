import React from 'react'

import { IAppDialogProps, IDiaglog } from './type'

const DialogHandler = createDialogHandler()
export function createDialogHandler() {
  let subscriberShow: ((data: IAppDialogProps) => void) | undefined
  let subscriberClose: ((name?: string | number) => void) | undefined
  let dialogProps: IAppDialogProps = {
    component: null,
  }
  const setDialogProps = (
    previousStateCallback: (data: IAppDialogProps) => IAppDialogProps,
  ) => {
    dialogProps = previousStateCallback(dialogProps)
    subscriberShow && subscriberShow(dialogProps)
  }
  const showDialog = (data: IAppDialogProps) => {
    setDialogProps((prev: IAppDialogProps) => {
      return {
        ...prev,
        component: data.component,
        options: data.options,
      }
    })
  }
  const closeDialog = (name?: string | number) => {
    subscriberClose && subscriberClose(name)
  }
  function use() {
    // eslint-disable-next-line
    let [dialogs, s] = React.useState<IDiaglog[]>([])
    const handleShowDialog = (data: IAppDialogProps) => {
      s([
        ...dialogs,
        {
          component: data.component,
          name: data?.options?.name || dialogs.length,
          open: true,
          options: data.options,
        },
      ])
    }
    const handleCloseDialog = (name?: string | number) => {
      if (name) {
        const newDialogs = dialogs.map(d => {
          if (d.name !== name) {
            return d
          }
          return {
            ...d,
            open: false,
          }
        })
        s(newDialogs)
        setTimeout(() => {
          s(dialogs.filter(item => item.name !== name))
        }, 200)
      } else {
        s(
          dialogs.map((d, index) => {
            if (index !== dialogs.length - 1) {
              return d
            }
            return {
              ...d,
              open: false,
            }
          }),
        )
        setTimeout(() => {
          s([...dialogs.slice(0, dialogs.length - 1)])
        }, 200)
      }
    }
    React.useEffect(() => {
      subscriberShow = handleShowDialog
      subscriberClose = handleCloseDialog
      return () => {
        subscriberShow = undefined
        subscriberClose = undefined
      }
    }, [s])

    return { dialogs }
  }
  return {
    api: {
      show: (data: IAppDialogProps) => showDialog(data),
      close: (name?: string | number) => closeDialog(name),
    },
    use,
  }
}
export const DialogApi = DialogHandler.api
export const useDialogHandler = DialogHandler.use
