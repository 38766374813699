import { DialogTitle, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const ButtonStyle = styled(Button)({
  color: 'black',
  textTransform: 'initial',

  '&:hover': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
    color: 'white',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
    color: 'white',
  },
})

export const DialogTitleCustom = styled(DialogTitle)({
  flex: 1,
})
export const Label = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
})
