import PropTypes from 'prop-types'
import React, { createContext, useContext, useMemo, useReducer } from 'react'

type AppState = {
  requesting: boolean
  hasSignedIn: boolean
  authInfo: any
  error: any
}

type AppAction = {
  type: string
  value?: any
}

// Material Dashboard 2 React main context
const AppContext = createContext<[AppState, React.Dispatch<AppAction>]>(
  [] as any,
)

// Setting custom name for the context which is visible on react dev tools
AppContext.displayName = 'AppContext'

// Material Dashboard 2 React reducer
function reducer(state: AppState, action: AppAction) {
  switch (action.type) {
    case 'SET_REQUESTING_SIGN_IN': {
      return { ...state, requesting: action.value }
    }
    case 'SET_SIGN_IN_SUCCESS': {
      return { ...state, authInfo: action.value, hasSignedIn: true }
    }
    case 'SET_SIGN_IN_ERROR': {
      return { ...state, error: action.value }
    }
    case 'SET_SIGN_OUT': {
      return { ...state, authInfo: {}, hasSignedIn: false, error: null }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AppControllerProvider({ children }: { children: any }) {
  const initialState = {
    requesting: false,
    hasSignedIn: false,
    authInfo: {},
    error: null,
  }

  const [controller, dispatch] = useReducer(reducer, initialState)

  const value: [AppState, React.Dispatch<AppAction>] = useMemo(
    () => [controller, dispatch],
    [controller, dispatch],
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

function useAppController() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error(
      'useAppController should be used inside the AppControllerProvider.',
    )
  }

  return context
}

AppControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Context module functions
const requestingSignIn = (dispatch: React.Dispatch<AppAction>, value: any) =>
  dispatch({ type: 'SET_REQUESTING_SIGN_IN', value })
const setSignInSuccess = (dispatch: React.Dispatch<AppAction>, value: any) =>
  dispatch({ type: 'SET_SIGN_IN_SUCCESS', value })
const setSignInError = (dispatch: React.Dispatch<AppAction>, value: any) =>
  dispatch({ type: 'SET_SIGN_IN_ERROR', value })

const setSignOut = (dispatch: React.Dispatch<AppAction>) =>
  dispatch({ type: 'SET_SIGN_OUT' })

export {
  AppControllerProvider,
  requestingSignIn,
  setSignInError,
  setSignInSuccess,
  setSignOut,
  useAppController,
}
