import { Query } from '@feathersjs/client'
import { Alert, Snackbar } from '@mui/material'
import Icon from '@mui/material/Icon'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import React, { useMemo, useState } from 'react'

import { DialogApi } from '../../components/Diaglog/DialogHandler'
import { downloadFile } from '../../helpers/user'
import { AppMenu, TMenu } from './../../components/Menu/Menu'
import { ProgressExport } from './ProgressExport'

export const ExportButton: React.FC<{ users?: any[]; query?: Query }> = ({
  users,
  query,
}) => {
  const [openError, setOpenError] = useState(false)
  const exportJson = users => {
    downloadFile({
      data: JSON.stringify(users),
      fileName: `whuups-users-export-${Date.now()}.json`,
      fileType: 'text/json',
    })
  }

  const exportToCsv = async users => {
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet()
    sheet.addRow([
      'Id',
      'Name',
      'Birthday',
      'Phone',
      'Address',
      'Email',
      'Hobbies',
      'Platform',
      'Lang',
      'Color Theme',
      'Status',
      'Deactivated',
    ])
    users.map(user => {
      const {
        _id,
        fullName,
        birthday,
        phone,
        status,
        address,
        email,
        hobbies,
        platform,
        colorTheme,
        lang,
        isDeactivated,
      } = user
      const dateOfBirth = JSON.stringify(birthday)

      sheet.addRow([
        _id,
        fullName,
        dateOfBirth,
        phone + '',
        address,
        email,
        hobbies.join(', '),
        platform,
        lang,
        colorTheme,
        status,
        isDeactivated,
      ])
    })
    const buffer2 = await workbook.csv.writeBuffer()
    saveAs(new Blob([buffer2]), `whuups-users-export-${Date.now()}.csv`)
  }

  const exportTo = (users, type) => {
    if (type === 0) {
      exportToCsv(users)
    } else {
      exportJson(users)
    }
  }

  const _showProgress = type => {
    DialogApi.show({
      component: () => (
        <ProgressExport
          exportTo={exportTo}
          type={type}
          query={query}
          exportFail={exportFail}
        />
      ),
      options: {
        onBackdropClick: () => {},
        maxWidth: 'md',
        sx: {
          '& .MuiDialog-paper': { width: '50%', maxWidth: 600, maxHeight: 700 },
        },
      },
    })
  }

  const exportFail = () => {
    DialogApi.close()
    setOpenError(true)
  }

  const exportTypes: TMenu[] = users
    ? [
        {
          label: 'Export csv',
          onClick: () => exportToCsv(users),
        },
        {
          label: 'Export json',
          onClick: () => exportJson(users),
        },
      ]
    : [
        {
          label: 'Export csv',
          onClick: () => _showProgress(0),
        },
        {
          label: 'Export json',
          onClick: () => _showProgress(1),
        },
      ]

  return (
    <>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setOpenError(false)}
          severity='error'
          sx={{ width: '100%' }}
        >
          Export error. Please try again
        </Alert>
      </Snackbar>
      <AppMenu
        buttonProps={{
          label: 'Export',
          endIcon: <Icon>download</Icon>,
        }}
        menuProps={{
          menus: exportTypes,
          PaperProps: {
            style: {
              maxWidth: 132,
            },
          },
        }}
      />
    </>
  )
}
