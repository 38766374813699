// @ts-nocheck
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export default function NotFound() {
  return (
    <MDBox pt={4} pb={3} px={3}>
      <MDBox display='flex' alignItems='center' justifyContent='center'>
        <MDTypography variant='h6'>404 Not found</MDTypography>
      </MDBox>
    </MDBox>
  )
}
