import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'

import { userService } from '.'

const ButtonStyle = styled(LoadingButton)({
  color: 'white',
  textTransform: 'initial',
})

export const DeactivateButton: React.FC<{
  userInfo: any
  cb?: (data: any) => void
}> = ({ userInfo, cb }) => {
  const [loading, setLoading] = useState(false)
  const handleClick = () => {
    setLoading(true)
    const params = { isDeactivated: userInfo.isDeactivated ? false : true }
    userService
      .patch(userInfo._id, params)
      .then(res => {
        cb && cb(res)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <ButtonStyle
      style={{
        backgroundColor: !userInfo?.isDeactivated ? 'red' : undefined,
        borderColor: !userInfo?.isDeactivated ? 'red' : undefined,
      }}
      loading={loading}
      onClick={handleClick}
      variant='contained'
    >
      {userInfo?.isDeactivated ? 'Re-Deactivate' : 'Deactivate'}
    </ButtonStyle>
  )
}
