import PropTypes from 'prop-types'
import React, { createContext, useContext, useMemo, useReducer } from 'react'

type MaterialUIState = {
  miniSidenav: boolean
  transparentSidenav: boolean
  whiteSidenav: boolean
  sidenavColor: string
  transparentNavbar: boolean
  fixedNavbar: boolean
  openConfigurator: boolean
  direction: string
  layout: string
  darkMode: string
}

type MaterialUIAction = {
  type: string
  value?: any
}

// Material Dashboard 2 React main context
const MaterialUI = createContext<
  [MaterialUIState, React.Dispatch<MaterialUIAction>]
>([] as any)

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = 'MaterialUIContext'

// Material Dashboard 2 React reducer
function reducer(state: MaterialUIState, action: MaterialUIAction) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value }
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value }
    }
    case 'WHITE_SIDENAV': {
      return { ...state, whiteSidenav: action.value }
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value }
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value }
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value }
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value }
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value }
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value }
    }
    case 'DARKMODE': {
      return { ...state, darkMode: action.value }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }: { children: any }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false,
  }

  const [controller, dispatch] = useReducer(reducer, initialState)

  const value: [MaterialUIState, React.Dispatch<MaterialUIAction>] = useMemo(
    () => [controller, dispatch],
    [controller, dispatch],
  )

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI)

  if (!context) {
    throw new Error(
      'useMaterialUIController should be used inside the MaterialUIControllerProvider.',
    )
  }

  return context
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Context module functions
const setMiniSidenav = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'MINI_SIDENAV', value })
const setTransparentSidenav = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'TRANSPARENT_SIDENAV', value })
const setWhiteSidenav = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'WHITE_SIDENAV', value })
const setSidenavColor = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'SIDENAV_COLOR', value })
const setTransparentNavbar = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'TRANSPARENT_NAVBAR', value })
const setFixedNavbar = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'FIXED_NAVBAR', value })
const setOpenConfigurator = (
  dispatch: React.Dispatch<MaterialUIAction>,
  value: any,
) => dispatch({ type: 'OPEN_CONFIGURATOR', value })
const setDirection = (dispatch: React.Dispatch<MaterialUIAction>, value: any) =>
  dispatch({ type: 'DIRECTION', value })
const setLayout = (dispatch: React.Dispatch<MaterialUIAction>, value: any) =>
  dispatch({ type: 'LAYOUT', value })
const setDarkMode = (dispatch: React.Dispatch<MaterialUIAction>, value: any) =>
  dispatch({ type: 'DARKMODE', value })

export {
  MaterialUIControllerProvider,
  setDarkMode,
  setDirection,
  setFixedNavbar,
  setLayout,
  setMiniSidenav,
  setOpenConfigurator,
  setSidenavColor,
  setTransparentNavbar,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
}
