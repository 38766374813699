import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import { getFileFullPath } from 'helpers'
import { useCallback, useEffect, useRef } from 'react'

import { Author } from './Author'

export type ReportedBy = {
  profilePicture?: string
  fullName?: string
  phone?: string
}

type ItemDialogProps = {
  open: boolean
  title: string | null
  image: string | null
  body: string | null
  service: string | null
  value: string | string[] | null
  reportedBy: ReportedBy | null
  reason: string | null
  onClose: (service?: string | null, value?: string | string[] | null) => void
}

export function ItemDialog({
  open,
  title,
  image,
  body,
  service,
  value,
  reportedBy,
  reason,
  onClose,
}: ItemDialogProps) {
  const descriptionElementRef = useRef(null)

  const handleCancel = useCallback(() => {
    onClose && onClose()
  }, [])

  const handleOk = useCallback(() => {
    onClose && onClose(service, value)
  }, [service, value])

  const handleIgnore = useCallback(() => {
    onClose && onClose('ignore', value)
  }, [value])

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        ;(descriptionElement as any).focus()
      }
    }
  }, [open])

  return (
    <Dialog
      maxWidth='xs'
      open={open}
      onClose={handleCancel}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      className='modalContainer'
    >
      <DialogContent dividers>
        {image && (
          <div className='modalImgContainer'>
            <img className='modalImg' src={image} />
          </div>
        )}
        <Typography variant='h5'>{title}</Typography>
        <DialogContentText
          id='scroll-dialog-description'
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='modalActions'>
        <div className='modalReportedByContainer'>
          <Author
            name={reportedBy?.fullName + ' | ' + reportedBy?.phone}
            image={getFileFullPath(reportedBy?.profilePicture)}
            email={reason}
          />
        </div>
        <div className='modalActionsBtns'>
          <Button onClick={handleIgnore} sx={{ color: '#1A73EB' }}>
            Ignore
          </Button>
          <Button onClick={handleOk} sx={{ color: 'red' }}>
            Delete
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
