import { Button as NativeButton } from '@mui/material'

type ButtonProps = {
  text: string
  className?: any
  color?: any
  startIcon?: any
  handleAction: (data: any) => (event: any) => any
}

export const ActionButton = (props: ButtonProps) => {
  return (
    <NativeButton
      size='medium'
      variant='text'
      onClick={props.handleAction}
      className={props.className}
      sx={{ color: props.color }}
      startIcon={props.startIcon}
    >
      {props.text}
    </NativeButton>
  )
}
