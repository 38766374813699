import { Dialog } from '@mui/material'
import React from 'react'

import { useDialogHandler } from './DialogHandler'

export const AppDialog: React.FC = () => {
  const { dialogs } = useDialogHandler()
  const components = dialogs
  if (components.length > 0) {
    return (
      <>
        {components.map((d, index) => {
          const { component, options, open } = d
          const ComponentRender = component
          return (
            <React.Fragment key={index}>
              <Dialog open={open} {...options}>
                <ComponentRender />
              </Dialog>
            </React.Fragment>
          )
        })}
      </>
    )
  }
  return null
}
