// @ts-nocheck
import Card from '@mui/material/Card'
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import { setSignInError, setSignInSuccess, useAppController } from 'context'
import { feathersApp } from 'helpers'
import { customSignInWithPhoneNumber } from 'helpers/firebase'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function Basic() {
  const navigate = useNavigate()
  const location = useLocation()
  const [appController, dispatch] = useAppController()
  const { error: apiError } = appController
  const [loading, setLoading] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')
  void code // eslint
  const [confirm, setConfirm] = useState(null)
  const phoneRef = useRef('')
  const codeRef = useRef('')
  const from = location.state?.from?.pathname || '/'

  const handleChangePhoneNo = useCallback(event => {
    phoneRef.current = event.target.value
    setPhoneNumber(phoneRef.current)
  }, [])

  const handleChangeCode = useCallback(event => {
    codeRef.current = event.target.value
    setCode(codeRef.current)
  }, [])

  const signInWithPhoneNumber = useCallback(async () => {
    if (phoneRef.current) {
      setLoading(true)
      try {
        const confirmation = await customSignInWithPhoneNumber(phoneRef.current)
        if (!confirmation) {
          throw new Error('Login failed, please try again later')
        }
        console.log(confirmation,"csnhdcjsdnsjn");
        setLoading(false)
        setConfirm(confirmation)
      } catch (error) {
        console.log(error,
          "how to cxome "
        );
        setSignInError(dispatch, error)
        setLoading(false)
      }
    }
  }, [dispatch])

  const getUser = useCallback(async () => {
    console.log('Phone number received:', phoneRef.current);
  
    if (phoneRef.current) {
      const params = {
        strategy: 'local',
        phone: phoneRef.current,
        password: "123456" // Check if this is expected or needs to be a different value
      };
     
      try {
        console.log(params,"params here");
        const res = await feathersApp.authenticate(params);
        console.log('Authentication response:', res);
  
        if (!res.user.roles || !Array.isArray(res.user.roles) || res.user.roles.indexOf('admin') === -1) {
          throw new Error('Unauthorized');
        }
  
        console.log('Access Token:', res.accessToken);
        setLoading(false);
        setSignInSuccess(dispatch, {
          accessToken: res.accessToken,
          ...res.user,
        });
        navigate(from, { replace: true });
      } catch (err) {
        console.error('Authentication failed:', err);
        setLoading(false); // Ensure loading state is reset
        setSignInError(dispatch, err);
      }
    } else {
      console.error('Phone number is not available.');
      setSignInError(dispatch, new Error('Please try again later'));
    }
  }, [dispatch, from, navigate]);
  

  const handleConfirmCode = useCallback(async () => {
    if (!confirm) {
      return setSignInError(dispatch, new Error('Please try again later'));
    }
  
    console.log("Attempting to confirm code:", codeRef.current);
  
    if (codeRef.current?.length === 6) {
      setLoading(true);
      try {
        const userCredential = await confirm.confirm(codeRef.current);
        
        console.log("User successfully signed in:", userCredential);
        
        const getuserData = await getUser();  // Assuming this is a backend call to fetch user data\
        console.log("fecth profile successfully",getuserData);
  
      } catch (err) {
        console.error("Error during code confirmation:", err);
        setSignInError(dispatch, err);
        setLoading(false);
      }
    } else {
      setSignInError(dispatch, new Error('Please set a valid code'));
    }
  }, [confirm, dispatch]);
  
  useEffect(() => {
    feathersApp
      .reAuthenticate()
      .then(res => {
        setLoading(false)
        if (
          !res.user.roles ||
          (Array.isArray(res.user.roles) &&
            res.user.roles.indexOf('admin') === -1)
        ) {
          throw new Error('Unauthorized')
        }

        setSignInSuccess(dispatch, {
          accessToken: res.accessToken,
          ...res.user,
        })
        navigate('/', { replace: true })
      })
      .catch(err => {
        setLoading(false)
      })
  }, [dispatch, navigate])

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component='form' role='form'>
            <MDBox mb={2} sx={{ display: Boolean(confirm) ? 'block' : 'none' }}>
              <MDInput
                type='text'
                label='Security code'
                defaultValue={null}
                disabled={loading}
                inputProps={{
                  maxLength: 6,
                  pattern: '[0-9]{1,6}',
                }}
                maxLength={6}
                onChange={handleChangeCode}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2} sx={{ display: Boolean(confirm) ? 'none' : 'block' }}>
              <MDInput
                type='text'
                label='Phone Number'
                defaultValue={phoneNumber}
                disabled={loading}
                onChange={handleChangePhoneNo}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant='h6' color='error'>
                {Boolean(apiError) ? apiError.message : ''}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant='gradient'
                color='info'
                fullWidth
                onClick={
                  Boolean(confirm) ? handleConfirmCode : signInWithPhoneNumber
                }
                disabled={loading}
              >
                {Boolean(confirm) ? 'confirm' : 'sign in'}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default Basic
