import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { DialogApi } from 'components/Diaglog/DialogHandler'
import { Author, PopoverItem, ReportedItem } from 'components/GridItem'
import { UserDetailPopup } from 'components/UserDetailPopup/UserDetails'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import { DATE_DDMMYYYY_HH_MM_SSS, getFileFullPath } from 'helpers'
import { useCallback, useMemo } from 'react'

export default function useData(
  result: Array<any> | null,
  handleOpenConfirmDlg: (service: string, value: string) => void,
  getReportImageFullPath?: (images?: string[]) => string | null,
) {
  const today = useMemo(() => new Date(), [])
  const handleClick = useCallback(
    (service, value) => (event: any) => {
      event?.stopPropagation()
      handleOpenConfirmDlg(service, value)
    },
    [handleOpenConfirmDlg],
  );

  console.log("result events")
  return useMemo(() => {
    return {
      columns: [
        { Header: 'id', accessor: 'id', align: 'left' },
        { Header: 'event', accessor: 'eventId', align: 'left', width: 450 },
        { Header: 'reason', accessor: 'content', align: 'center' },
        { Header: 'reported by', accessor: 'userId', align: 'right' },
        { Header: 'action', accessor: 'action', align: 'center' },
      ],
      rows: Array.isArray(result)
        ? result.map(r => ({
            id: <Typography variant='caption'>{r._id}</Typography>,
            eventId: (
              <ReportedItem
                image={
                  (getReportImageFullPath &&
                    getReportImageFullPath(r.event?.images)) ??
                  ''
                }
                title={r.event?.title}
                onClick={() => {
                  DialogApi.show({
                    component: () => (
                      <UserDetailPopup userId={r.event?.adminId} />
                    ),
                    options: {
                      onBackdropClick: () => {
                        DialogApi.close()
                      },
                      maxWidth: 'xs',
                      sx: {
                        '& .MuiDialog-paper': {
                          width: '50%',
                          maxWidth: 300,
                          maxHeight: 700,
                        },
                      },
                    },
                  })
                }}
                subTitle={r.event?.about}
                onDeleteLabel='Delete Event'
                onDeleteAction={handleClick('events', r._id)}
              />
            ),
            content: (
              <PopoverItem
                name={r._id}
                contentVariant='caption'
                content={`${r.content} - ${formatRelative(
                  new Date(r.createdAt),
                  today,
                )}`}
                popoverContent={format(
                  new Date(r.createdAt),
                  DATE_DDMMYYYY_HH_MM_SSS,
                )}
              />
            ),
            userId: (
              <Author
                image={getFileFullPath(r.user?.profilePicture)}
                name={r.user?.fullName}
                email={r.user?.phone}
                handleOnClick={() =>
                  DialogApi.show({
                    component: () => <UserDetailPopup userId={r.user._id} />,
                    options: {
                      onBackdropClick: () => {
                        DialogApi.close()
                      },
                      maxWidth: 'xs',
                      sx: {
                        '& .MuiDialog-paper': {
                          width: '50%',
                          maxWidth: 300,
                          maxHeight: 700,
                        },
                      },
                    },
                  })
                }
              />
            ),
            action: (
              <Button
                color='primary'
                size='small'
                onClick={handleClick('event-reports', r._id)}
                variant='text'
                className='ignoreReportBtn'
              >
                Ignore Report
              </Button>
            ),
          }))
        : [],
    }
  }, [result, handleClick])
}
